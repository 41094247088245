<template>
  <div class="yh_container">
    <el-form class="form" ref="form" :model="formData" :rules="rules">
      <el-row :gutter="16">
        <el-col v-if="ind == 4 || ind == 0" :span="24">
          <el-form-item label="上级菜单">
            <el-cascader
              v-model="parentId"
              :options="options"
              :props="treeProps"
              :show-all-levels="false"
              @change="handleChange"
              clearable
              :disabled="ind == 4"
              placeholder="/"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="菜单类型">
            <el-radio-group v-model="formData.menuType">
              <el-radio :label="'M'">模块</el-radio>
              <el-radio :label="'C'">菜单</el-radio>
              <el-radio :label="'F'">权限</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="菜单名称"
            prop="menuName"
            :rules="{ required: true, trigger: 'blur', message: '菜单名称不能为空' }"
          >
            <el-input
              v-model="formData.menuName"
              placeholder="请输入菜单名称"
              clearable
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formData.menuType == 'C'">
          <el-form-item label="菜单图标">
            <upload
              :callbackUrl="defaultIconUrl"
              :upStyle="'btnIcon'"
              :upTitle="'正常状态'"
              @up-success="upLoadDefaultUrl"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formData.menuType == 'C'">
          <el-form-item label="菜单图标">
            <upload
              :callbackUrl="defaultIconUrl"
              :upStyle="'btnIcon'"
              :upTitle="'选中状态'"
              @up-success="upLoadDefaultUrl"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formData.menuType != '3'">
          <el-form-item label="是否外链">
            <el-radio-group v-model="formData.isFrame">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.menuType != 'F'">
          <el-form-item label="路由地址" prop="path">
            <el-input placeholder="请输入路由地址" v-model="formData.path" maxlength="50" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.menuType == 'C'">
          <el-form-item label="组件路径">
            <el-input v-model="formData.component" placeholder="请输入组件路径" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.menuType == 'C'">
          <el-form-item label="菜单排序">
            <el-input 
              v-model.number="formData.orderNum" 
              type="number" 
              min="0" 
              placeholder="请输入菜单排序" 
              clearable
              @keydown.native="channelInputLimit" />
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.menuType == 'F'">
          <el-form-item label="权限字符">
            <el-input v-model="formData.perms" placeholder="请输入权限标识" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态">
            <el-radio-group v-model="formData.status">
              <el-radio :label="'1'">正常</el-radio>
              <el-radio :label="'0'">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="yh_btn">
      <el-button class="btn btn_blue" @click="confirm">提交</el-button>
      <el-button class="btn btn_blue btn_normal" @click="cancle">取消</el-button>
    </div>
  </div>
</template>

<script>
import upload from '@comps/backStageComponent/upLoads.vue';
import menuAPI from '@apis/backStageEndAPI/menuManagementAPI';
export default {
  name: 'addMenu',
  components: {
    upload
  },
  props: ['rowData', 'treeData', 'index'],
  watch: {
    rowData: {
      handler(val) {
        this.formData = Object.assign({}, val);
        this.init(val);
      },
      immediate: true
    },
    treeData: {
      handler(val) {
        this.options = val;
      },
      immediate: true,
      deep: true
    },
    index: {
      handler(newVal) {
        this.ind = newVal;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      defaultIconUrl: '',
      formData: {
        menuName: '',
        parentId: 0,
        path: '',
        component: '',
        menuType: '',
        status: '',
        perms: '',
        icon: '',
        selectedIcon: null,
        children: [],
        ancestors: '',
        createTime: '',
        isFrame: '',
        orderNum: '',
      },
      parentId: [],
      rules: {},
      options: [],
      treeProps: {
        value: 'menuId',
        label: 'menuName',
        children: 'children',
        checkStrictly: true
      }
    };
  },
  methods: {
    upLoadDefaultUrl(val) {
      // console.log(val)
    },
    init(val) {
      let tem = val.ancestors ? val.ancestors.split(',') : [];
      this.parentId = [];
      tem.forEach((item) => {
        this.parentId.push(parseInt(item));
      });
      this.parentId.shift();
    },
    handleChange(val) {
      this.formData.ancestors = val;
    },
    async confirm() {
      let res = {};
      const params = this.formData;
      params.parentId = this.parentId[this.parentId.length - 1];
      if (params.menuType === 'C') {
        delete params.perms;
      } else if (params.menuType === 'M') {
        delete params.component;
        delete params.perms;
        delete params.orderNum
      } else if (params.menuType === 'F') {
        delete params.component;
        delete params.perms;
        delete params.path;
        delete params.orderNum
      }
      if (!this.rowData.menuId) {
        res = await menuAPI.addMenu(params);
      } else {
        res = await menuAPI.updateMenu(params);
      }
      this.$emit('closeMenuDia', true);
    },
    cancle() {
      this.$emit('closeMenuDia', true);
    },
    channelInputLimit(e) {
      let key = e.key
      if (key === 'e' || key === '.' || key === '-') {
        e.returnValue = false
        return false
      }
        return true
    }
  }
};
</script>

<style lang="scss" scoped>
.yh_container {
  position: relative;
  .title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
  }
  .yh_btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
::v-deep {
  .el-cascader .el-input .el-input__inner {
    width: 550px;
  }
  .el-col-12 {
    margin-bottom: 10px;
  }
  .el-col-24 {
    margin-bottom: 10px;
  }
}
</style>
