<template>
  <div class="container">
    <header-bar></header-bar>
    <div class="yh_btn">
      <el-button class="btn btn_blue" icon="el-icon-arrow-right" @click.stop="open(true)">
        {{ !isExpand ? '展开' : '收起' }}
      </el-button>
      <el-button class="btn btn_orange" icon="el-icon-plus" @click.stop="add">新建</el-button>
    </div>
    <el-table
      highlight-current-row
      :data="treeData"
      ref="selectTree"
      row-key="menuId"
      border
      lazy
      center
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="菜单名称" prop="menuName">
        <template slot-scope="scoped">
          <span v-if="scoped.row.children.length > 0">
            {{ scoped.row.menuName }}
          </span>
          <span v-else class="rgba_lf">
            {{ scoped.row.menuName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="菜单类型" prop="menuType">
        <template slot-scope="scope">
          <div v-if="scope.row.menuType === 'M'">模块</div>
          <div v-else-if="scope.row.menuType === 'C'">菜单</div>
          <div v-else-if="scope.row.menuType === 'F'">权限</div>
          <div v-else>其它</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          <div :class="{ statusSign: scope.row.status == '0' }">
            {{ scope.row.status === '1' ? '启用' : '禁用' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" />
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <span class="operate color_blue" @click="update(scope)">编辑</span>
          <span class="operate color_red" @click="del(scope)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="dialog_wrapper"
      :title="title"
      :close-on-click-modal="false"
      width="690px"
      :visible.sync="isAddMenu"
    >
      <add-menu :row-data="row" :tree-data="treeData" :index="index" @closeMenuDia="closeMenu" />
    </el-dialog>
  </div>
</template>

<script>
import headerBar from '@comps/backStageComponent/layout/headerBar/headerBar';
import addMenu from './components/addMenu';
import menuAPI from '@apis/backStageEndAPI/menuManagementAPI';

export default {
  name: 'menuManagement',
  components: {
    headerBar,
    addMenu,
  },
  data() {
    return {
      isAddMenu: false,
      isExpand: false,
      treeData: [],
      row: {},
      title: '新建菜单',
      index: 0
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let res = await menuAPI.getMenuList();
      this.treeData = res;
    },
    update(scope) {
      this.row = Object.assign({}, scope.row);
      this.title = '编辑菜单';
      this.index = 4
      this.isAddMenu = true;
    },
    async del(scope) {
      this.$confirm('您是要删除当前记录吗？', '提示', {
        confirmButtonText: '确定',
        cancleButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(async () => {
        let res = await menuAPI.delMenu(scope.row.menuId);
        this.init();
      })
      .catch(err => {
        this.$message.error(err);
      });
    },
    open(isAss) {
      this.isExpand = !this.isExpand;
      this.$nextTick(() => {
        this.forArr(this.treeData, this.isExpand);
      });
    },
    forArr(arr, isExpand) {
      arr.forEach(i => {
        this.$refs.selectTree.toggleRowExpansion(i, isExpand);
        if (i.children) {
          this.forArr(i.children, isExpand);
        }
      });
    },
    add() {
      this.title = '新建菜单';
      this.row = {};
      this.index = 0
      this.isAddMenu = true;
    },
    closeMenu() {
      this.isAddMenu = false;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__placeholder {
	width: 0 !important;
}
.rgba_lf {
	margin-left: 30px !important;
}
.yh_btn {
	margin: 0 0 8px 5px;
}
.statusSign {
	color: pink;
}
::v-deep {
	.el-table {
		td,
		th {
			padding: 6px 0;
			color: #222;
			text-align: center;
			font-size: 16px;
		}
		.cell .yh_span {
			margin: 0 15px;
			text-decoration: underline;
			font-size: 16px;
			cursor: pointer;
		}
	}
	.el-table .el-table__cell {
		padding: 10px 0;
	}
	.el-table th.el-table__cell {
		border-radius: 3%;
	}
}
</style>
